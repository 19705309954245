import { Injectable } from "@angular/core";
import { GeneralUtils } from "./general";

@Injectable({
    providedIn: 'root'
})
export class Utils {
    constructor(
        private generalUtils: GeneralUtils
    ) {
    }

    getAPIUrl() {
        return this.generalUtils.getAPIUrl();
    }
    
    getImageHost() {
        return this.generalUtils.getImageHost();
    }

    isCurrentDate(moveInDate: Date, moveOutDate: Date) {
        return this.generalUtils.isCurrentDate(moveInDate, moveOutDate);
    }

    isOverDate(moveInDate: Date, moveOutDate: Date) {
        return this.generalUtils.isOverDate(moveInDate, moveOutDate);
    }

    isUpcomingDate(moveInDate: Date, moveOutDate: Date) {
        return this.generalUtils.isUpcomingDate(moveInDate, moveOutDate);
    }

    getUrlPath() {
        return this.generalUtils.getUrlPath();
    }

    getApiModeString(): string {
        return this.generalUtils.getApiModeString();
    }

    isLongTermStay(moveInDate: Date, moveOutDate: Date): boolean {
        return this.generalUtils.isLongTermStay(moveInDate, moveOutDate);
    }
    
    capitalize(input: string): string {
        if (input.length === 0) return input;
        return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
    }
    
    isAtBeginningOfMonth(date: Date): boolean {
        return this.generalUtils.isAtBeginningOfMonth(date);
    }
} 